<template>
  <v-app>
    <div>
           <v-card class="mx-8 mt-9" >
               <div class="pt-5 d-flex justify-center">
                   Enter Email ID
               </div>
          <v-text-field
          class="mx-5 pt-5"
            label="E-mail"
            v-model="email"
            hint="Enter your e-mail address "
            type="e-mail"
          ></v-text-field>
          <div class=" d-flex justify-end mx-7 mt-5 pb-5">
<v-btn v-on:click="reset()" button class="primary"> Submit </v-btn>
          </div>
          
           </v-card>
    </div>
     
       
         
       
   
  </v-app>
</template>
<script>
import firebase from 'firebase/app';
import "firebase/auth"
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    reset() {
     var auth = firebase.auth();


auth.sendPasswordResetEmail(this.email).then(function() {
 window.alert("Check your Email")
     this.$router.push("Login")
 
}).catch(function(error) {
  window.alert(error)
});
    },
  },
};
</script>